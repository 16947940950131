/* eslint-disable sort-imports */

// Set global.applicationHistory so that on-location-changed will listen on it
import history from "shared/history.js"
import {callbacksHandler} from "on-location-changed/src/callbacks-handler"

callbacksHandler.connectReactRouterHistory(history)

// Use our own logging tool to log errors when testing
import "shared/error-logger"

// HayaSelect
import BodyPortal from "components/body-portal"
import {default as HayaSelectConfig} from "haya-select/src/config"

HayaSelectConfig.setBodyPortal(BodyPortal)

// API maker
import {default as ApiMakerConfig} from "@kaspernj/api-maker/src/config"
import currenciesCollection from "shared/currencies-collection"

ApiMakerConfig.setCurrenciesCollection(currenciesCollection)
ApiMakerConfig.setHistory(history)
ApiMakerConfig.setModal(() => Modal)

// React layout and application
import "shared/action-cable-logging"
import "shared/react-native-vector-icons"
import "shared/source-maps"
import "shared/time-zone-offset"
import "components/application"
import "controllers"
import "stylesheets/application"
